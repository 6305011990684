import { isMobile } from 'react-device-detect';
import { signOut } from 'next-auth/client';
import { configuration } from './configuration';

/* eslint-disable no-undef */
export function getQueryParams() {
  const query = new URLSearchParams(window.location.search);
  return query;
}

/**
 * Returns the number of seconds before the token expires.
 * @returns number of seconds left until the token expires.  Min value is 0.
 * 
 * The 'authenticated' page sets the access token in localstorage.
 */
export const getSecondsUntilTokenExpires = () => {
  const accessTokenExpiration = localStorage.getItem('accessTokenExpiration');
  const accessTokenExpirationDate = new Date(+accessTokenExpiration);
  const now = new Date();
  const deltaDates = (accessTokenExpirationDate - now);
  const secondsUntilTokenExpires = Math.max(0, Math.ceil(deltaDates / 1000));
  return secondsUntilTokenExpires;
};

export const hasTokenExpired = (accessTokenExpiration) => {
  if ((!accessTokenExpiration) || (accessTokenExpiration === '')) {
    return true;
  }
  const accessTokenExpirationDate = new Date(+accessTokenExpiration);
  const now = new Date();
  const deltaDates = (accessTokenExpirationDate - now);
  const secondsUntilTokenExpires = Math.max(0, Math.ceil(deltaDates / 1000));
  return (secondsUntilTokenExpires <= 0);
};

const popupCenter = ({ url, title, w, h }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop
  const newWindow = window.open(
    url,
    title,
    `
scrollbars=yes,
width=${w / systemZoom}, 
height=${h / systemZoom}, 
top=${top}, 
left=${left}
`,
  )

  if (window.focus && newWindow) {
    newWindow.focus()
  }
  return newWindow
}

export const logout = async (callbackUrl) => {
  const iotCommunityLogoutURL = configuration.getLogoutIoTCommunityURL();
  const popup = popupCenter({
    url: iotCommunityLogoutURL,
    title: 'Salesforce Logged Out',
    w: 351,
    h: 500
  })

  // Give the popup 4 seconds to load, then close it and redirect to home page.
  // We can't detect when the page is loaded due to cross-origin restrictions, this is best we have so far.
  setTimeout(() => {
    if (popup) {
      popup.close();
    }
    removeAuthenticationData();
    localStorage.removeItem('kcode');

    signOut({
      redirect: true,
      callbackUrl: callbackUrl,
    })
  }, 4000)
}

export const removeAuthenticationData = () => {
  localStorage.setItem('accessTokenExpiration', '0');
  localStorage.removeItem('jsforce0_access_token');
  localStorage.removeItem('email');
  localStorage.removeItem('zipitUrl');
  localStorage.removeItem('zipitUrlWithoutCatalogItemId');
  sessionStorage.clear();
  caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
}

/**
 * Returns the string equivalent of the passed in JSON object, with some whitespace
 * added to the structure elements of the stringified json.  This is helpful when displaying
 * the json string with word wrap.
 * @param json - The JSON object to convert
 * @returns    - The word-wrap friendly stringified json
 */
export const convertJSONToStringWithSpaces = (json) => {
  const data = JSON.stringify(json);
  const dataWithSpacesAdded = data.replaceAll(/":/g, '": ').replaceAll(/,"/g, ', "').replaceAll(/,{/g, ', {').replaceAll(/,\[/g, ', [');
  return dataWithSpacesAdded;
};

/**
 * Opens the provided URL in a new window.
 * @param {*} url 
 */
export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

// from: https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
export const getPlatform = () => {
  if (isMobile) {
    const userAgent =
      window.navigator.userAgent || window.navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
  } else {
    return "Desktop";
  }

  return "unknown";
};