import * as HttpStatus from 'http-status-codes';
import { ISurveyResponse } from '../components/Survey/InitialPanel';
class APIService {
  sftoken: any;

  constructor(sftoken: string | null = null) {
    this.sftoken = sftoken;
  }

  getAccessToken = (): string => {
    let token: string | null = this.sftoken;
    if (token) {
      token = "Bearer " + token;
    } else {
      token = "";
    }
    return token;
  };

  async deleteData(url = ""): Promise<Response> {
    return this.fetchData("DELETE", url);
  }

  async putData(url = "", data: object = {}): Promise<Response> {
    return this.fetchData("PUT", url, data);
  }

  async getData(url = ""): Promise<Response> {
    return this.fetchData("GET", url);
  }

  async postData(url = "", data: object = {}): Promise<Response> {
    return this.fetchData("POST", url, data);
  }

  async fetchData(
    method: string,
    url = "",
    data: object = {}
  ): Promise<Response> {
    const params: any = {
      method: method,
      cache: "no-cache",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      headers: {
        accept: "text/json",
        "Content-Type": "text/json",
        Authorization: this.getAccessToken(),
      },
    };

    // If using localhost then remove authorization param, similar to what we do in the data-hooks for accessing locally hosted APIs.
    if (url.indexOf('//localhost:') > 0) {
      delete params.headers.Authorization;
    }

    if (method.toUpperCase() !== "GET") {
      params.body = JSON.stringify(data);
    }

    const response = await fetch(url, params);
    return response;
  }

  async retrieveIsDeviceOwner(claimCode: any): Promise<any> {
    const url = `/api/protected/is-device-owner/${claimCode}`;
    return this.getData(url)
      .then((response: any) => {
        if (response.ok) {
          const jsn = response.json();
          return jsn;
        } else {
          const statusText = HttpStatus.getStatusText(response.status);
          const msg = {
            ok: false,
            status: response.status,
            statusText: statusText,
            error: `Unable to retrieve isDeviceOwner from: ${url}`
          };
          return Promise.reject(msg);
        }
      })
      .then((entry: any) => {
        return entry;
      })
  }

  async retrieveBuildId(): Promise<any> {
    const url = '/api/build-id';
    return this.getData(url)
      .then((response: any) => {
        if (response.ok) {
          return response.json();
        } else {
          const statusText = HttpStatus.getStatusText(response.status);
          const msg = `Unable to retrieve Build Id:\nUrl: ${response.url}\nStatus: ${statusText + " ( " + response.status + ")"}`;
          return Promise.reject(msg);
        }
      })
      .then((entry: any) => {
        return entry.buildId;
      })
  }

  async submitSurveyResults(surveyResults: ISurveyResponse): Promise<any> {
    const url = '/api/protected/submit-survey';
    return this.postData(url, surveyResults)
      .then((response: any) => {
        if (response.ok) {
          return response.json();
        } else {
          const statusText = HttpStatus.getStatusText(response.status);
          const msg = `Unable to record survey results.\nUrl: ${response.url}\nStatus: ${statusText + " ( " + response.status + ")"}`;
          return Promise.reject(msg);
        }
      })
      .then((entry: any) => {
        return entry;
      })
  }
}



export default APIService;

// This export is designed to be used with 'useSWR()'.
export const fetcher = async (url: string, accessToken: string, method = 'GET', data: object = {}): Promise<any> => {
  const params: any = {
    method: method,
    cache: 'no-cache',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    headers: {
      accept: 'text/json',
      'Content-Type': 'text/json',
      Authorization: 'Bearer ' + accessToken,
    }
  }

  if (method.toUpperCase() !== "GET") {
    params.body = JSON.stringify(data);
  }

  const res = await fetch(url, params);
  return await res.json();
}
