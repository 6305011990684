class Styles {
  classes: any;

  constructor(classes: any) {
    this.classes = classes;
  }

  // Used to construct name mangled styling for one or more styles.
  // It also helps to catch missing styles by logging them in the console.
  //
  // This is used in conjunction with css modules.
  //
  // Usage:
  // ...
  // import classes from './BatteryVoltageLow.module.scss';
  // ...
  // function BatteryVoltageLow(props) {
  // ...
  // const cx = new Styles(classes).cx;
  //
  // ...
  // <div className={cx('dark-blue-background icon sub-section')}>

  cx = (styles: string) => {
    if ('' === styles)
    {
      return '';
    }
    const getClass = (style: string) => {
      const mangledStyle = this.classes[style];
      if (!mangledStyle) {
        console.error('Style is missing from css file: ', style);
      }
      return mangledStyle;
    }

    const stylesArray = styles.split(' ');
    const filteredStylesArray = stylesArray.filter((e) => e !== '');
    if (filteredStylesArray.length === 1) {
      return getClass(filteredStylesArray[0]);
    }

    const reducedArray = filteredStylesArray.reduce((pV, cV, index) => {
      //console.log('index: ', index, '   pV: ', pV, '   cV: ', cV);
      if (index === 1) {
        const mangledPV = pV ? getClass(pV) : undefined;
        const mangledCV = cV ? getClass(cV) : undefined;
        //console.log('mangledPV: ', mangledPV,'   mangledCV: ', mangledCV);
        const retVal = (mangledPV ? `${mangledPV} ` : '') + mangledCV;
        //console.log('retVal: ', retVal);
        return retVal;
      } else {
        //console.log('pV: ', pV, '   classes[cV]: ', getClass(cV));
        return pV + ' ' + getClass(cV);
      }
    });
    return reducedArray;
  }


}

export default Styles;
