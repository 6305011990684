import APIService from "./api-service";

export interface IApplicationInsights {
  name?: string,
  kcode?: string | null,
  page?: string | null,
  component?: string | null,
  index?: string | null,
  contactId?: string | null,
  properties?: any | null,
  developerMode?: string | null,
  href?: string | null,
  buildId?: string | null,
  [key: string]: string | null | undefined | object
}

export default async function recordApplicationInsights(data: IApplicationInsights) {

  const getLocalStorageValue = (item: string, defaultValue: string | null): string | null => {
    const storageValue = localStorage.getItem(item);
    const retValue = storageValue ? storageValue : defaultValue;
    return retValue;
  }

  // If the property is already defined then leave it unchanged, otherwise set it to the default value;
  const updateProperty = (data: IApplicationInsights, property: string, defaultValue: string | object | null): void => {
    if (!data[property]) {
      data[property] = defaultValue;
    }
  }

  const url = '/api/application-insights';

  // Name is set to the default: 'POST /api/application-insights'.
  // These can be overridden by simply providing values for them when calling the function.
  updateProperty(data, 'kcode', getLocalStorageValue('kcode', 'undefined'));
  updateProperty(data, 'page', getLocalStorageValue('page', 'undefined'));
  updateProperty(data, 'developerMode', getLocalStorageValue('developerMode', 'undefined'));
  updateProperty(data, 'index', 'undefined');
  updateProperty(data, 'href', 'undefined');
  updateProperty(data, 'component', 'undefined');
  updateProperty(data, 'contactId', 'undefined');
  updateProperty(data, 'properties', {});
  updateProperty(data, 'name', `POST ${url}`);
  updateProperty(data, 'buildId', getLocalStorageValue('buildId', null));

  // If buildId is not set, then make sync call to API to fetch it, and then update localstorage.
  // This is to help with edge case where message is being written to Application Insights before the Layout component has updated localStorage with the buildId.
  if (!data['buildId']) {
    const apiService = new APIService(null);
    const buildId = await apiService.retrieveBuildId();
    data['buildId'] = buildId;
    localStorage.setItem('buildId', buildId);
  }

  // Replace {page} with data['page'].
  data['name'] = (data['name'] && data['page']) ? data['name'].replaceAll(/{page}/ig, data['page']) : 'undefined';

  // Use a caching mechanism to prevent us from recording duplicate events.
  // After 2 seconds of inactivity we allow the next event to be recorded even if it is the same as last one sent.
  const stringifiedData = JSON.stringify(data);
  const lastAppInsightsPost = localStorage.getItem('lastAppInsightsPost');
  const now = Date.now();
  const previousDate = localStorage.getItem('lastTimeAppInsightsCalled');
  const allowCallToProceed = !previousDate || ((+previousDate + 2000) < now);
  if ((stringifiedData !== lastAppInsightsPost) || (allowCallToProceed)) {
    localStorage.setItem('lastAppInsightsPost', stringifiedData);
    localStorage.setItem('lastTimeAppInsightsCalled', '' + now);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    if (response.status === 403) {
      console.log('Application Insights is not available to the client.');
    } else if (response.status === 200) {
      await response.json();
      console.log('ApplicationInsights Data: ', data);
    } else {
      console.warn('Unexpected status code from api: ', response);
    }
  }
}
